<template>
  <div>
    <b-card-code title="ez Date Range Picker">
      <b-row>
        <!-- default -->
        <b-col md="6">
          <b-form-group>
            <h5 class="font-weight-bold">Default</h5>
            {{ dateRange }}
            <br />
            {{ test }}
            <date-range-picker
              ref="picker"
              opens="right"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
              :singleDatePicker="true"
              :timePicker="true"
              :timePicker24Hour="true"
              :showWeekNumbers="true"
              :showDropdowns="false"
              :autoApply="true"
              v-model="dateRange"
              :ranges="false"
              :appendToBody="false"
              :linkedCalendars="false"
            >
              <!-- :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }" -->
              <!-- @update="updateValues"
              @toggle="logEvent('event: open', $event)"
              @start-selection="logEvent('event: startSelection', $event)"
              @finish-selection="logEvent('event: finishSelection', $event)"
              :linkedCalendars="linkedCalendars"
              :dateFormat="dateFormat" -->
              <!-- <template v-slot:input="picker" style="min-width: 350px">
                {{ picker.startDate }} - {{ picker.endDate }}
              </template> -->
            </date-range-picker>
          </b-form-group>
        </b-col>
      </b-row>

      <template #code>
        {{ codeBasic }}
      </template>
    </b-card-code>
    <b-card-code title="Flat Picker">
      <b-row>
        <!-- default -->
        <b-col md="6">
          <b-form-group>
            <h5 class="font-weight-bold">Default</h5>
            <flat-pickr v-model="dateDefault" class="form-control" />
          </b-form-group>
        </b-col>

        <!-- time picker -->
        <b-col md="6">
          <b-form-group>
            <h5>Time picker</h5>
            <flat-pickr
              v-model="timePicker"
              class="form-control"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
              }"
            />
          </b-form-group>
        </b-col>

        <!-- date and time -->
        <b-col md="6">
          <b-form-group>
            <h5>Date & TIme</h5>
            <flat-pickr
              v-model="dateNtim"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
            />
          </b-form-group>
        </b-col>

        <!-- multiple dates -->
        <b-col md="6">
          <b-form-group>
            <h5>Multiple Dates</h5>
            <flat-pickr
              v-model="multiDate"
              class="form-control"
              :config="{ mode: 'multiple', dateFormat: 'Y-m-d' }"
            />
          </b-form-group>
        </b-col>

        <!-- range -->
        <b-col md="6">
          <b-form-group>
            <h5>Range</h5>
            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              :config="{ mode: 'range' }"
            />
          </b-form-group>
        </b-col>

        <!-- human friendly -->
        <b-col md="6">
          <b-form-group>
            <h5>Human Friendly</h5>
            <flat-pickr
              v-model="humanDate"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
              }"
            />
          </b-form-group>
        </b-col>

        <!-- disabled range -->
        <b-col md="6">
          <b-form-group>
            <h5>Disabled Range</h5>
            <flat-pickr
              v-model="disableDate"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
                disable: [{ from: '2020-08-20', to: '2020-08-25' }],
              }"
            />
          </b-form-group>
        </b-col>

        <!-- inline -->
        <b-col md="6">
          <b-form-group>
            <h5>Inline</h5>
            <flat-pickr
              v-model="inlineDate"
              class="form-control"
              :config="{ inline: true }"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #code>
        {{ codeBasic }}
      </template>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import { BRow, BCol, BFormGroup } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { codeBasic } from './code';
import DateRangePicker from '@core/components/ez-date-picker';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc');
// var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
// const dayjs = require('dayjs');
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('America/New_York');

//you need to import the CSS manually
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components: {
    BRow,
    BCol,

    flatPickr,
    BCardCode,
    BFormGroup,
    DateRangePicker,
  },
  data() {
    return {
      dateRange: {
        startDate: dayjs('2018-01-01').utc().format(),
        endDate: dayjs().utc().format(),
      },
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      codeBasic,
      test: dayjs().utc().format(),
    };
  },
  created() {
    // console.log(1, dayjs());
    // dayjs.tz.setDefault('America/New_York');
    // console.log(2, dayjs().tz('America/Toronto'));
  },
  methods: {
    dateFormat(classes, date) {
      console.log(date);
      return date;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
